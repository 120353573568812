<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h1>Perfil de Competências</h1>
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <Divider />
      <Button
        label="Adicionar Competência"
        icon="pi pi-plus"
        class="p-button-success p-button-outlined"
        @click="showForm = true"
      />
      <Divider />
      <DataTable
        :value="competencyList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        :paginator="true"
        :rows="20"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
        :sortOrder="1"
        editMode="row"
        dataKey="id"
        :editingRows.sync="editingRows"
        @row-edit-save="onRowEditSave"
        @row-edit-cancel="onRowEditCancel"
        responsiveLayout="scroll"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column field="name" header="Nome" sortable filterField="name">
          <template #body="slotProps">
            <span class="p-column-title">Nome:</span>
            {{ slotProps.data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
          <template #editor="slotProps">
            <InputText
              :name="slotProps.column.field"
              v-model="slotProps.data[slotProps.column.field]"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            <span class="p-column-title">Activa:</span>
            {{ slotProps.data.active == true ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: false, label: 'Não' },
                { value: true, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
          <template #editor="slotProps">
            <Dropdown
              v-model="slotProps.data[slotProps.column.field]"
              class="p-column-filter"
              optionLabel="label"
              optionValue="value"
              :options="[
                { value: false, label: 'Não' },
                { value: true, label: 'Sim' },
              ]"
            />
          </template>
        </Column>
        <!-- <Column field="avg" header="Valor médio" sortable>
          <template #body="slotProps">
            <span class="p-column-title">Valor médio:</span>
            {{ slotProps.data.avg }}
          </template>
        </Column> -->
        <Column
          :rowEditor="true"
          :styles="{ width: '10px' }"
          :bodyStyle="{ 'text-align': 'right' }"
          :headerStyle="{ width: '100px' }"
        >
        </Column>
        <Column
          :styles="{ width: '10px' }"
          :bodyStyle="{ 'text-align': 'left' }"
          :headerStyle="{ width: '50px' }"
        >
          <template #body="slotProps">
            <Button
              v-tooltip.left="'Ver Competência'"
              icon="pi pi-eye"
              class="p-button-rounded p-button-text p-button-plain"
              @click="
                $router.push(`/competency-profile-view/${slotProps.data.id}`)
              "
            />
          </template>
        </Column>
      </DataTable>

      <Dialog
        :visible.sync="showForm"
        :style="{ width: $isMobile() ? '100vw' : '40vw' }"
        :header="'Criar Competência'"
        :modal="true"
        :contentStyle="{ overflow: 'visible' }"
      >
        <form
          name="vacationsForm"
          class="p-col-12"
          @submit.prevent="saveNewCompetency"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
              <span class="p-float-label">
                <InputText
                  type="text"
                  maxlength="60"
                  v-validate="'required'"
                  v-model="newCompetencyName"
                  v-bind:class="[
                    { 'p-invalid': errors.has('newCompetencyName') },
                    'form-control',
                  ]"
                  :id="'newCompetencyName'"
                  :name="'newCompetencyName'"
                />
                <label for="newCompetencyName">Nome da Competência</label>
              </span>
              <small
                v-if="errors.has('newCompetencyName')"
                class="p-error"
                role="alert"
              >
                Nome é obrigatório
              </small>
            </div>
          </div>
        </form>

        <template #footer>
          <Button
            :label="'Criar Competência'"
            icon="pi pi-check"
            class="p-button-text p-button-success"
            @click="saveNewCompetency"
          />
          <Button
            label="Cancelar"
            icon="pi pi-times"
            class="p-button-text p-button-danger"
            @click="closeForm"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import competencyService from "../services/competency.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "CompetencyProfile",

  data() {
    return {
      loading: true,
      showForm: false,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        field: { value: null, matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      competencyList: [],
      competencyListOri: [],
      newCompetencyName: null,
      editingRows: [],
    };
  },
  async created() {
    await this.getCompetencyProfile();
    this.loading = false;
  },
  methods: {
    getCompetencyProfile() {
      return competencyService.getCompetencyProfile().then((response) => {
        return (this.competencyList = response);
      });
    },
    closeForm() {
      this.showForm = false;
      this.newCompetencyName = null;
    },
    saveNewCompetency() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        return competencyService
          .createCompetency({ name: this.newCompetencyName })
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro ao criar",
                detail:
                  "Provavelmete o nome da competência já se encontra em uso",
                life: 3000,
              });
            }
            this.competencyList.push(response);
            this.closeForm();
            return this.$toast.add({
              severity: "success",
              summary: "Competência Criada",
              detail: "A Competência foi criada com sucesso",
              life: 3000,
            });
          });
      });
    },
    async onRowEditCancel() {
      await this.getCompetencyProfile();
    },
    onRowEditSave(event) {
      let bodyParams = {
        name: event.data.name,
        status: event.data.active == true ? 1 : 0,
      };
      return competencyService
        .updateCompetency(event.data.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao Editar",
              detail:
                "Provavelmete o nome da competência já se encontra em uso",
              life: 3000,
            });
          }
          return this.$toast.add({
            severity: "success",
            summary: "Competência Editada",
            detail: "A Competência foi editada com sucesso",
            life: 3000,
          });
        });
    },
  },
};
</script>